/**
 * Rounds a number to a specified number of decimal places using various
 * rounding modes.
 *
 * Based on Ruby's BigDecimal rounding modes.
 *
 * @param {number} value - The number to round.
 * @param {number} decimalPlaces - The number of decimal places to round to.
 * @param {number} roundingMode - The rounding mode to use.
 *   Supported modes (as integers):
 *   - 1: Round away from zero (UP)
 *   - 2: Round towards zero (DOWN/truncate)
 *   - 3: Round to nearest neighbor, ties away from zero (HALF_UP)
 *   - 4: Round to nearest neighbor, ties towards zero (HALF_DOWN)
 *   - 5: Round towards positive infinity (CEILING)
 *   - 6: Round towards negative infinity (FLOOR)
 *   - 7: Round to nearest neighbor, ties to even digit (HALF_EVEN, Banker's)
 *
 * @returns {number} The rounded number.
 *
 * Example usage:
 *
 *  console.log(roundWithMode(3.14159, 2, 3)); // 3.14 (HALF_UP)
 *  console.log(roundWithMode(3.14159, 2, 7)); // 3.14 (HALF_EVEN)
 *  console.log(roundWithMode(3.15, 1, 3)); // 3.2 (HALF_UP)
 *  console.log(roundWithMode(3.15, 1, 7)); // 3.2 (HALF_EVEN)
 *  console.log(roundWithMode(-3.15, 1, 3)); // -3.2 (HALF_UP)
 *  console.log(roundWithMode(-3.15, 1, 5)); // -3.1 (CEILING)
 *  console.log(roundWithMode(-3.15, 1, 6)); // -3.2 (FLOOR)
 */
export default function roundWithMode(value, decimalPlaces, roundingMode = 3) {
  if (isNaN(value) || !isFinite(value)) {
    return value; // Return NaN or Infinity as is
  }

  // Guard against non-integer or negative decimal places
  decimalPlaces = Math.max(0, Math.floor(decimalPlaces));

  const multiplier = Math.pow(10, decimalPlaces);
  const isValueNegative = value < 0;
  const absoluteValue = Math.abs(value);

  // Calculate values needed for rounding decisions
  const shiftedValue = absoluteValue * multiplier;
  const integerPart = Math.floor(shiftedValue);
  const fraction = shiftedValue - integerPart;

  let roundedAbsValue;

  switch (roundingMode) {
    case 1: // UP: Away from zero
      roundedAbsValue =
        fraction > 0
          ? (integerPart + 1) / multiplier
          : integerPart / multiplier;
      break;

    case 2: // DOWN: Towards zero (truncate)
      roundedAbsValue = integerPart / multiplier;
      break;

    case 3: // HALF_UP: Round to nearest, ties away from zero
      roundedAbsValue =
        fraction >= 0.5
          ? (integerPart + 1) / multiplier
          : integerPart / multiplier;
      break;

    case 4: // HALF_DOWN: Round to nearest, ties towards zero
      roundedAbsValue =
        fraction > 0.5
          ? (integerPart + 1) / multiplier
          : integerPart / multiplier;
      break;

    case 5: // CEILING: Towards positive infinity
      if (isValueNegative) {
        roundedAbsValue = integerPart / multiplier;
      } else {
        roundedAbsValue =
          fraction > 0
            ? (integerPart + 1) / multiplier
            : integerPart / multiplier;
      }
      break;

    case 6: // FLOOR: Towards negative infinity
      if (isValueNegative) {
        roundedAbsValue =
          fraction > 0
            ? (integerPart + 1) / multiplier
            : integerPart / multiplier;
      } else {
        roundedAbsValue = integerPart / multiplier;
      }
      break;

    case 7: // HALF_EVEN: Banker's rounding
      if (fraction > 0.5) {
        roundedAbsValue = (integerPart + 1) / multiplier;
      } else if (fraction < 0.5) {
        roundedAbsValue = integerPart / multiplier;
      } else {
        roundedAbsValue =
          integerPart % 2 === 0
            ? integerPart / multiplier
            : (integerPart + 1) / multiplier;
      }
      break;

    default:
      throw new Error(`Unsupported rounding mode: ${roundingMode}`);
  }

  return isValueNegative ? -roundedAbsValue : roundedAbsValue;
}
