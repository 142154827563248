import { jpCalculateTaxFromTotal } from "../../shared/tax_calculator";

/**
 * Calculates the total amount including tax for a collection of items
 * @param {Array} items - Array of items with properties totalPrice/totalPriceExclTax, tax, taxRate, taxIncludedInTotal
 * @param {string} vatCalculationMode - Mode of VAT calculation ('line_items_based' or 'total_based')
 * @param {string} roundingMode - The rounding method to use for calculations
 * @returns {number} The total amount including tax
 */
function calculateItemsTotal(items, vatCalculationMode, roundingMode) {
  switch (vatCalculationMode) {
    case "line_items_based":
      // Simple sum of pre-calculated tax and price values
      return sumProps(items, ["totalPriceExclTax", "tax"]);
    case "total_based": {
      // Get unique tax rates to handle items with different rates separately
      const rates = uniqueTaxRatePercentages(items);
      let total = 0;

      rates.forEach((rate) => {
        // Group items by their tax rate
        const itemsWithRate = items.filter((item) => item.taxRate === rate);

        // Handle items that already include tax
        const itemsWithTaxIncluded = itemsWithRate.filter(
          (item) => item.taxIncludedInTotal,
        );
        const totalPriceTaxIncluded = sumProps(itemsWithTaxIncluded, [
          "totalPrice",
        ]);

        total += totalPriceTaxIncluded;

        // Handle items that don't include tax
        const itemsWithTaxNotIncluded = itemsWithRate.filter(
          (item) => !item.taxIncludedInTotal,
        );
        const totalPriceTaxNotIncluded = sumProps(itemsWithTaxNotIncluded, [
          "totalPrice",
        ]);

        // Add price and calculated tax for non-included items
        total +=
          totalPriceTaxNotIncluded +
          jpCalculateTaxFromTotal(
            totalPriceTaxNotIncluded,
            rate,
            false,
            roundingMode,
          );
      });

      return total;
    }
    default:
      return sumProps(items, ["totalPriceExclTax", "tax"]);
  }
}

/**
 * Calculates the total tax amount for a collection of items
 * @param {Array} items - Array of items with properties totalPrice, tax, taxRate, taxIncludedInTotal
 * @param {string} vatCalculationMode - Mode of VAT calculation ('line_items_based' or 'total_based')
 * @param {string} roundingMode - The rounding method to use for calculations
 * @returns {number} The total tax amount
 */
function calculateItemsTax(items, vatCalculationMode, roundingMode) {
  switch (vatCalculationMode) {
    case "line_items_based":
      return sumProps(items, ["tax"]);
    case "total_based": {
      const rates = uniqueTaxRatePercentages(items);
      let totalTax = 0;

      rates.forEach((rate) => {
        const itemsWithRate = items.filter((item) => item.taxRate === rate);
        const itemsWithTaxIncluded = itemsWithRate.filter(
          (item) => item.taxIncludedInTotal,
        );
        const totalPriceTaxIncluded = sumProps(itemsWithTaxIncluded, [
          "totalPrice",
        ]);

        totalTax += jpCalculateTaxFromTotal(
          totalPriceTaxIncluded,
          rate,
          true,
          roundingMode,
        );

        const itemsWithTaxNotIncluded = itemsWithRate.filter(
          (item) => !item.taxIncludedInTotal,
        );
        const totalPriceTaxNotIncluded = sumProps(itemsWithTaxNotIncluded, [
          "totalPrice",
        ]);

        totalTax += jpCalculateTaxFromTotal(
          totalPriceTaxNotIncluded,
          rate,
          false,
          roundingMode,
        );
      });

      return totalTax;
    }
    default:
      return sumProps(items, ["tax"]);
  }
}

/**
 * Sums up specified numeric properties from an array of objects
 * @param {Array} items - Array of objects containing the properties to sum
 * @param {Array<string>} props - Array of property names to sum
 * @returns {number} The sum of all specified properties
 */
function sumProps(items, props) {
  let acc = 0;
  items.forEach((item) => {
    props.forEach((prop) => {
      acc += parseFloat(item[prop]);
    });
  });
  return acc;
}

/**
 * Extracts unique tax rate percentages from an array of items
 * @param {Array} items - Array of items with taxRate property
 * @returns {Array<number>} Array of unique tax rates
 */
function uniqueTaxRatePercentages(items) {
  return [...new Set(items.map((item) => item.taxRate))];
}

export {
  calculateItemsTotal,
  calculateItemsTax,
  sumProps,
  uniqueTaxRatePercentages,
};
