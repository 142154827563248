import { Controller } from "@hotwired/stimulus";
import * as Sentry from "@sentry/browser";
import Compressor from "compressorjs";

export default class extends Controller {
  static targets = ["verifiedBadge"];
  static values = {
    ocrEnabled: Boolean,
  };

  async handleFileSelected(event) {
    if (!this.ocrEnabledValue) {
      return;
    }

    const { form } = event.detail;
    const formUrl = form.action;
    const formData = new FormData(form);
    const csrfToken = document.querySelector(
      'meta[name="csrf-token"]',
    )?.content;

    this.dispatch("openModal");

    // show loading -> analyzing
    // makes it feel faster
    setTimeout(() => {
      this.dispatch("showScreen", { detail: { name: "processing" } });
    }, 1250);

    this.abortController = new AbortController();

    // minify image
    const file = formData.get("proof[image]");
    if (file && file.type.startsWith("image/")) {
      const compressedImage = await this.compressImage(file);
      // need to provide file name, make sure it's jpeg
      const jpegName = file.name.replace(/\.[^/.]+$/, ".jpg");
      formData.set("proof[image]", compressedImage, jpegName);
    }

    formData.set("proof[landing_date]", null);
    formData.set("proof[residence_status]", null);

    let response;
    try {
      response = await fetch(formUrl, {
        method: "PUT",
        body: formData,
        credentials: "same-origin",
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
        },
        signal: this.abortController.signal,
      });
    } catch (e) {
      if (e.toString() === "User aborted") {
        this.dispatch("closeModal");
      } else {
        Sentry.captureException(e);
        this.handleError();
        this.markValid(false);
      }
      return;
    }

    // make sure we clear the image proof and don't re-submit it
    document.querySelector('input[name="proof[image]"]').value = "";

    // Generic error or a validation error (expired permit)
    if (!response.ok) {
      try {
        const errorData = await response.json();
        this.handleError(errorData.error);

        // Update form with OCR data
        form.elements["proof[landing_date]"].value = errorData.landing_date;
        form.elements["proof[residence_status]"].value =
          errorData.residence_status;
      } catch {
        this.handleError();
      }
      this.markValid(false);
      return;
    }

    try {
      // Update form with OCR data
      const data = await response.json();
      form.elements["proof[landing_date]"].value = data.landing_date;
      form.elements["proof[residence_status]"].value = data.residence_status;

      // Handle OCR validation
      if (!data.success) {
        this.handleError(data.message);
        this.markValid(false);
      } else {
        this.dispatch("closeModal");
        this.markValid();
      }
    } catch (error) {
      Sentry.captureException(error);
      this.handleError();
      this.markValid(false);
    }
  }

  handleError(message = "Something went wrong.") {
    this.dispatch("showError", { detail: { message } });
  }

  handleOcrCancel() {
    this.abortController.abort("User aborted");
  }

  markValid(valid = true) {
    if (valid) {
      this.verifiedBadgeTarget.classList.remove("d-none");
      this.verifiedBadgeTarget.classList.add("d-block");
    } else {
      this.verifiedBadgeTarget.classList.add("d-none");
      this.verifiedBadgeTarget.classList.remove("d-block");
    }
  }

  async compressImage(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        // File size really matters to the OCR + upload time.
        // This will produce about 200kb/file, reducing the total
        // processing time from 6 to 4 seconds (compared with 1M prior).
        maxWidth: 1500,
        quality: 0.3,
        mimeType: "image/jpeg",
        convertTypes: ["image/png", "image/webp"],
        success: resolve,
        error: reject,
      });
    });
  }
}
