import "choices.js/public/assets/styles/choices.css";

import { Controller } from "@hotwired/stimulus";
import * as Choices from "choices.js";

export default class extends Controller {
  static classes = ["outerContainer"];

  connect() {
    new Choices(this.element, {
      removeItemButton: this.element.hasAttribute("multiple"),
      classNames: {
        containerOuter: [
          `choices ${this.hasOuterContainerClass ? this.outerContainerClasses.join(" ") : ""}`,
        ],
      },
    });
  }
}
