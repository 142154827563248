import roundWithMode from "./rounding.js";

/*
 * Rounding mode here is consistent with Rails default rounding
 * that is currently being used on object creation. This happens
 * to match the majority of our partner stores (for now)
 */
function jpCalculateTaxFromTotal(
  total,
  taxRate,
  taxIncluded,
  roundingMode = 3,
) {
  if (total === 0) {
    return 0;
  }

  if (taxIncluded) {
    return calcTaxFromTotalIncludingTax(total, taxRate, roundingMode);
  } else {
    return calcTaxFromTotalExcludingTax(total, taxRate, roundingMode);
  }
}

function calcTaxFromTotalIncludingTax(total, taxRate, roundingMode) {
  const totalBeforeTax = parseFloat(total / (1 + taxRate));

  return roundWithMode(parseFloat(total - totalBeforeTax), 0, roundingMode);
}

function calcTaxFromTotalExcludingTax(total, taxRate, roundingMode) {
  return roundWithMode(parseFloat(total * taxRate), 0, roundingMode);
}

function convertToDecimalRate(value) {
  return Number(value) / 100;
}

export { convertToDecimalRate, jpCalculateTaxFromTotal };
