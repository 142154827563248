import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { calculateItemsTotal, calculateItemsTax, sumProps } from "./amounts";

export default class extends Controller {
  static outlets = ["jp-merchant-operation--line-item"];

  static targets = [
    "addNewItem",
    "consumableItemsTax",
    "consumableItemsTotal",
    "eightPercentTax",
    "eightPercentTotal",
    "generalItemsTax",
    "generalItemsTotal",
    "orderTax",
    "orderTotal",
    "required",
    "tenPercentTax",
    "tenPercentTotal",
    "itemsCounter",
    "applyCategoryToAllModal",
    "categoryName",
    "confirmTotalsWarningModal",
    "totalBelowMinimumWarning",
    "consumableTotalAboveMaximumWarning",
  ];

  static values = { roundingMode: Number, vatCalculationMode: String };

  connect() {
    if (
      !this.hasJpMerchantOperationLineItemOutlet &&
      this.hasAddNewItemTarget
    ) {
      this.addNewItemTarget.click();
    }

    this.refresh();
  }

  jpMerchantOperationLineItemOutletConnected(outlet, _) {
    // noop if we simply render persisted line items
    if (outlet.persistedValue) {
      return;
    }

    // Retrieve template outlet before the new one was added
    const protoLineItem = this.#getPreviousOutlet(outlet);

    // Make a newly added line item to inherit some properties from the previous
    // line item.
    if (protoLineItem) {
      outlet.categoryTarget.value = protoLineItem.categoryTarget.value;

      outlet.reducedTaxRateTarget.checked =
        protoLineItem.reducedTaxRateTarget.checked;
      outlet.reducedTaxRateTarget.dispatchEvent(new Event("change"));

      outlet.isConsumableTarget.checked =
        protoLineItem.isConsumableTarget.checked;
      outlet.isConsumableTarget.dispatchEvent(new Event("change"));

      outlet.taxIncludedInTotalTarget.checked =
        protoLineItem.taxIncludedInTotalTarget.checked;
      outlet.taxIncludedInTotalTarget.dispatchEvent(new Event("change"));
    }
  }

  // Recursively searches for the element (with controller on it) that is
  // the closest to the new outlet and returns the controller instance for
  // that outlet.
  #getPreviousOutlet(outlet) {
    let previousSibling = outlet.element.previousElementSibling;

    while (previousSibling) {
      if (
        previousSibling.hasAttribute("data-controller") &&
        previousSibling
          .getAttribute("data-controller")
          .includes("jp-merchant-operation--line-item")
      ) {
        const templateOutlet =
          this.application.getControllerForElementAndIdentifier(
            previousSibling,
            "jp-merchant-operation--line-item",
          );
        return templateOutlet;
      }
      previousSibling = previousSibling.previousElementSibling;
    }

    return null;
  }

  refresh() {
    this.orderTotalTargets.forEach((target) => {
      target.innerHTML = this.formatAmount(this.calculateOrderTotal());
    });

    this.orderTaxTargets.forEach((target) => {
      target.innerHTML = this.formatAmount(this.calculateOrderTax());
    });

    this.tenPercentTotalTarget.innerHTML = this.formatAmount(
      this.calculateTenPercentItemsTotal(),
    );
    this.tenPercentTaxTarget.innerHTML = this.formatAmount(
      this.calculateTenPercentItemsTax(),
    );

    this.eightPercentTotalTarget.innerHTML = this.formatAmount(
      this.calculateEightPercentTotal(),
    );
    this.eightPercentTaxTarget.innerHTML = this.formatAmount(
      this.calculateEightPercentItemsTax(),
    );
    this.generalItemsTotalTarget.innerHTML = this.formatAmount(
      this.calculateGeneralItemsTotal(),
    );
    this.generalItemsTaxTarget.innerHTML = this.formatAmount(
      this.calculateGeneralItemsTax(),
    );
    this.consumableItemsTotalTarget.innerHTML = this.formatAmount(
      this.calculateConsumableItemsTotal(),
    );
    this.consumableItemsTaxTarget.innerHTML = this.formatAmount(
      this.calculateConsumableItemsTax(),
    );

    this.itemsCounterTarget.innerHTML = this.calculateTotalNumberOfItems();

    // Update some warnings
    const totalExcludingTax = this.calculateOrderTotalExclTax();
    const consTotalExlTax = this.calculateConsumableItemsTotalExclTax();

    this.showOrHideMinimumTotalWarning(totalExcludingTax);
    this.showOrHideConsumableTotalAboveMaximumWarning(consTotalExlTax);
  }

  //
  // Items scopes
  //

  // All line items including hidden ones that were marked for destruction.
  items() {
    return this.jpMerchantOperationLineItemOutlets;
  }

  // All visible items.
  keptItems() {
    return this.items().filter((outlet) => !outlet.markedForDestruction());
  }

  // All items with standard tax rate.
  tenPercentItems() {
    return this.keptItems().filter((outlet) => outlet.taxRate === 0.1);
  }

  // All items with reduced tax rate.
  eightPercentItems() {
    return this.keptItems().filter((outlet) => outlet.taxRate === 0.08);
  }

  // All general (non-consumable) items.
  generalItems() {
    return this.keptItems().filter((outlet) => !outlet.isConsumable);
  }

  // All consumable items.
  consumableItems() {
    return this.keptItems().filter((outlet) => outlet.isConsumable);
  }

  //
  // Summary calculations
  //
  calculateOrderTotal() {
    return calculateItemsTotal(
      this.keptItems(),
      this.vatCalculationModeValue,
      this.roundingModeValue,
    );
  }

  calculateOrderTax() {
    return (
      this.calculateTenPercentItemsTax() + this.calculateEightPercentItemsTax()
    );
  }

  calculateTenPercentItemsTax() {
    return calculateItemsTax(
      this.tenPercentItems(),
      this.vatCalculationModeValue,
      this.roundingModeValue,
    );
  }

  calculateEightPercentItemsTax() {
    return calculateItemsTax(
      this.eightPercentItems(),
      this.vatCalculationModeValue,
      this.roundingModeValue,
    );
  }

  calculateTenPercentItemsTotal() {
    return sumProps(this.tenPercentItems(), ["totalPriceExclTax", "tax"]);
  }

  calculateEightPercentTotal() {
    return calculateItemsTotal(
      this.eightPercentItems(),
      this.vatCalculationModeValue,
      this.roundingModeValue,
    );
  }

  calculateGeneralItemsTotal() {
    return calculateItemsTotal(
      this.generalItems(),
      this.vatCalculationModeValue,
      this.roundingModeValue,
    );
  }

  calculateGeneralItemsTax() {
    return calculateItemsTax(
      this.generalItems(),
      this.vatCalculationModeValue,
      this.roundingModeValue,
    );
  }

  calculateConsumableItemsTotal() {
    return calculateItemsTotal(
      this.consumableItems(),
      this.vatCalculationModeValue,
      this.roundingModeValue,
    );
  }

  calculateConsumableItemsTax() {
    return calculateItemsTax(
      this.consumableItems(),
      this.vatCalculationModeValue,
      this.roundingModeValue,
    );
  }

  calculateConsumableItemsTotalExclTax() {
    return sumProps(this.consumableItems(), ["totalPriceExclTax"]);
  }

  calculateOrderTotalExclTax() {
    return sumProps(this.keptItems(), ["totalPriceExclTax"]);
  }

  calculateTotalNumberOfItems() {
    return sumProps(this.keptItems(), ["quantity"]);
  }

  formatAmount(amount) {
    return new Intl.NumberFormat("ja-JP", {
      style: "currency",
      currency: "JPY",
      maximumFractionDigits: 0,
    }).format(amount);
  }

  toggleReducedTaxRate() {
    this.#toggleApplyAllAction(
      "toggleReducedTaxRateIcon",
      (item) => !item.isReducedTaxRate,
    );
  }

  toggleConsumable() {
    this.#toggleApplyAllAction(
      "toggleConsumableIcon",
      (item) => !item.isConsumable,
    );
  }

  toggleTaxIncludedInTotal() {
    this.#toggleApplyAllAction(
      "toggleTaxIncludedInTotalIcon",
      (item) => !item.taxIncludedInTotal,
    );
  }

  toggleAlcohol() {
    this.#toggleApplyAllAction("toggleAlcoholIcon", (item) => !item.isAlcohol);
  }

  //
  // Apply Category to All
  //

  showApplyCategoryToAllModal(event) {
    const selector = event.target;

    if (
      selector.value !== "" && // don't apply empty category
      this.keptItems().length > 1 && // don't apply to single line item
      this.isChildOfFirstKeptLineItem(selector) // triggered by first item
    ) {
      this.categoryNameTarget.innerText = selector.value;
      this.applyCategoryToAllModal.modal("show");
    }
  }

  get applyCategoryToAllModal() {
    const modal = $(`#${this.applyCategoryToAllModalTarget.id}`);

    return modal;
  }

  triggerApplyCategoryToAll() {
    this.applyCategoryToAllModal.modal("hide");
    this.applyCategoryToAll(this.keptItems()[0].categoryTarget.value);
  }

  isChildOfFirstKeptLineItem(element) {
    return this.keptItems()[0].element.contains(element);
  }

  applyCategoryToAll(category) {
    this.keptItems().forEach((outlet) => {
      outlet.categoryTarget.value = category;
    });
  }

  removeAllLineItems() {
    this.items().filter((outlet) => {
      outlet.destroyButtonTarget.click();
    });
    this.addNewItemTarget.click();
  }

  openConfirmTotalsWarningModal(_event) {
    const modal = $(`#${this.confirmTotalsWarningModalTarget.id}`);

    modal.modal("show");
  }

  showOrHideMinimumTotalWarning(orderTotalExclTax) {
    // This is hardcoded, we currently don't have this min check enforced
    // because we don't have the ability to combine orders yet
    // TODO(Dom): Have this loaded in from somewhere to match logic elsewhere
    if (orderTotalExclTax < 5000) {
      this.totalBelowMinimumWarningTarget.classList.remove("d-none");
    } else {
      this.totalBelowMinimumWarningTarget.classList.add("d-none");
    }
  }

  showOrHideConsumableTotalAboveMaximumWarning(orderTotalExclTax) {
    // This is hardcoded, we currently don't have this maximum consumable
    if (orderTotalExclTax > 500000) {
      this.consumableTotalAboveMaximumWarningTarget.classList.remove("d-none");
    } else {
      this.consumableTotalAboveMaximumWarningTarget.classList.add("d-none");
    }
  }

  //
  // PRIVATE
  //

  #toggleApplyAllAction(targetName, filter) {
    if (this.items().some(filter)) {
      this.items()
        .filter(filter)
        .forEach((item) => {
          item.targets.find(targetName).click();
        });
    } else {
      this.items().forEach((item) => {
        item.targets.find(targetName).click();
      });
    }
  }
}
